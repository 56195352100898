import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { useLocation, useNavigate } from 'react-router-dom';
import commonApi from '../utiles/api';
import { Badge } from 'reactstrap';

export const CommonContext = createContext();

export function CommonContextProvider({ children, setUserProfile }) {
    const navigate = useNavigate();
    const location = useLocation();
    const decryptData = (encryptedData, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    const handleLogout = () => {
        localStorage.removeItem('memberIOV');
        localStorage.removeItem('memberToken');
        localStorage.removeItem('memberConfig');
        if (user?.user_type === 'branch') {
            navigate('/branch-signin');
        } else {
            navigate('/signin');
        }
    };
    const secretKey = 'member-iov';
    const storedUserData = localStorage.getItem('memberIOV');
    const configData = localStorage.getItem('memberConfig');

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [cityDropDown, setCityDropDown] = useState('');
    const [stateDropDown, setStateDropDown] = useState('');
    const [user, setUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const [siteSettings, setSiteSettings] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
    const [isHovered, setIsHovered] = React.useState(false);
    const [seoData, setSeoData] = useState('');
    const [indexData, setIndexData] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [configVar, setConfigVar] = useState('');
    const [assetsClassDrop, setAssetsClassDrop] = useState([]);
    const [aAssetsClassDrop, setAAssetsClassDrop] = useState([]);
    const [mClassDrop, setMClassDrop] = useState([]);
    const [defaultBG, setDefaultBG] = useState(null);

    useEffect(() => {
        const dBanner =
            indexData &&
            indexData?.settings?.length > 0 &&
            indexData?.settings.find((item) => item.option_key === "default_banner")
                ?.option_value;
        if (dBanner) {
            setDefaultBG(`${indexData?.settings_path}/${dBanner}`);
        }
    }, [indexData]);

    const renderFileDownloadButton = (fileUrl) => {
        return (
            <a href={fileUrl} download target="_blank" rel="noopener noreferrer">
                <Badge type="button" color="info">
                    Download File
                </Badge>
            </a>
        );
    };
    console.log('user', user);
    const renderFilePreview = (fileUrl, width = '100px') => {
        const fileExtension = fileUrl.split('.').pop().toLowerCase();
        const isPdf = fileExtension === 'pdf';

        if (isPdf) {
            return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <embed
                        src={fileUrl}
                        type="application/pdf"
                        width={width}
                        height={width}
                    />
                </a>
            );
        } else {
            return (
                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                    <img
                        src={fileUrl}
                        alt="Selected File"
                        style={{ maxWidth: '100%', width }}
                    />
                </a>
            );
        }
    };
    const onMClassDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/class/dropdown');
            if (response.data.s === 1) {
                setMClassDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onAssetsClassDrop = async (parentId) => {
        const postReq = {}
        if (parentId) {
            postReq.parent_id = parentId
        }
        setIsLoading(true);
        try {
            const response = await commonApi.post('assets-class/dropdown', postReq);
            if (response.data.s === 1) {
                if (parentId) {
                    setAAssetsClassDrop(response.data.data);
                } else {
                    setAssetsClassDrop(response.data.data);
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (configData) {
            setConfigVar(JSON.parse(configData));
        }
        onAssetsClassDrop();
        onMClassDrop();
        // }, []);
    }, [configData]);
    useEffect(() => {
        setSeoData('');
    }, [location.pathname]);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onSeoApi = async () => {
        setIsLoading(true);
        setSeoData('');
        try {
            const sanitizedPathname = location.pathname;
            const response = await commonApi.post('seo/front', {
                url: sanitizedPathname,
            });
            if (response.data.s === 1) {
                setSeoData(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onStateDropDown = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('state/dropdown');
            if (response.data.s === 1) {
                setStateDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onHomeIndexApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('home/index');
            if (response.data) {
                setIndexData(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onSiteSetting = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('site-settings/get');
            if (response.data.s === 1) {
                setSiteSettings(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (location.pathname !== '/') {
            onSeoApi();
        }
        onHomeIndexApi();
        onStateDropDown();
    }, [location]);
    useEffect(() => {
        if (storedUserData) {
            const parsedUser = decryptData(storedUserData, secretKey);
            if (parsedUser?.permissions) {
                setUserPermission(JSON.parse(parsedUser?.permissions))
            }
            setUser(parsedUser);
            setUserProfile(parsedUser);
            // onSiteSetting();
        } else {
            setUser(null);
            setUserProfile(null);
        }
        // if (!storedUserData) {
        //     navigate('/signin');
        // }
    }, [storedUserData]);
    const contextValue = {
        defaultBG,
        renderFileDownloadButton,
        mClassDrop,
        assetsClassDrop,
        renderFilePreview,
        configVar,
        isMobile,
        indexData,
        setIndexData,
        onSeoApi,
        seoData,
        siteSettings,
        setSiteSettings,
        onSiteSetting,
        cityDropDown,
        setCityDropDown,
        stateDropDown,
        setStateDropDown,
        isHovered,
        setIsHovered,
        isSidebarOpen,
        setIsSidebarOpen,
        handleLogout,
        isSearch,
        globalSearch,
        user,
        userPermission,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setUser,
        setUserPermission,
        setGlobalSearch,
        setIsSearch
    };

    return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
}
